import gsap from 'gsap';
import CSSRulePlugin from 'gsap/CSSRulePlugin';

export default class MainMenu {
  constructor(element) {
    this.element = element;

    this.scrollPosition = 0;
    this.lastScrollPosition = 0;
    this.html = document.documentElement;

    this.tl = gsap.timeline({ paused: true });
    this.path = document.querySelector('path');
    this.start = 'M0 502S175 272 500 272s500 230 500 230V0H0Z';
    this.end = 'M0, 1005S175, 995, 500, 995s500, 5, 500, 5V0H0Z';

    this.init();
    this.initPlugins();
    this.revealMenu();
  }

  init() {
    gsap.set('.menu', { visibility: 'hidden' });
    //this.onResize();

    window.addEventListener('scroll', this.onScroll.bind(this));
    //window.addEventListener('resize', this.onResize.bind(this));
  }

  initPlugins() {
    gsap.registerPlugin(CSSRulePlugin);
  }

  onScroll() {
    this.lastScrollPosition = this.scrollPosition;
    this.scrollPosition = document.scrollingElement.scrollTop;

    this.setDirections();
    this.setState();
  }

  // onResize() {
  //   const isMobile = window.matchMedia('(max-width: 1023px)').matches;

  //   if (isMobile) {
  //     this.end = 'M0, 2010S175, 1990, 500, 1990s500, 10, 500, 10V0H0Z';
  //   } else {
  //     this.end = 'M0, 1005S175, 995, 500, 995s500, 5, 500, 5V0H0Z';
  //   }
  // }

  setState() {
    const socials = document.querySelector('.socials.head');
    const theme = document.querySelector('.theme__toggle');

    if (this.html.classList.contains('is-scrolling-down')) {
      socials.classList.add('has-scrolled');
      theme.classList.add('has-scrolled');
    } else if (this.html.classList.contains('is-scrolling-up')) {
      socials.classList.remove('has-scrolled');
      theme.classList.remove('has-scrolled');
    }
  }

  setDirections() {
    if (this.scrollPosition >= this.lastScrollPosition) {
      this.html.classList.add('is-scrolling-down');
      this.html.classList.remove('is-scrolling-up');
    } else {
      this.html.classList.remove('is-scrolling-down');
      this.html.classList.add('is-scrolling-up');
    }
  }

  revealMenu() {
    this.revealMenuItems();

    const toggle = this.element.querySelector('.menu__toggle');
    toggle.addEventListener('click', this.onToggleNav.bind(this));
  }

  revealMenuItems() {
    this.tl
      .to(this.path, {
        duration: 0.8,
        attr: {
          d: this.start,
        },
        ease: 'power2.in',
      })
      .to(
        this.path,
        {
          duration: 1,
          attr: {
            d: this.end,
          },
          ease: 'power4.out',
        },
        '-=0.5'
      )
      .to(
        '.menu',
        {
          duration: 0.6,
          visibility: 'visible',
        },
        '-=1'
      )
      .to(
        '.menu_item > a',
        {
          duration: 1,
          top: 0,
          ease: 'power3.out',
          stagger: {
            amount: 0.3,
          },
        },
        '-=0.5'
      )
      .reverse();
  }

  onToggleNav() {
    this.html.classList.toggle('nav-is-active');
    this.tl.reversed(!this.tl.reversed());
  }
}
