export default class Fullscreen {
  constructor(element) {
    this.element = element;

    this.section = this.element.closest('section');
    this.media = this.element.querySelector('.media');
    this.html = document.documentElement;

    this.init();
  }

  init() {
    const open = this.element.querySelector('.icon.fullscreen');
    open.addEventListener('click', this.setFullscreen.bind(this));

    const close = this.element.querySelector('.close__container');
    close.addEventListener('click', this.closeFullscreen.bind(this));
  }

  setFullscreen() {
    this.section.classList.add('fullscreen');
    this.media.classList.add('fullscreen');
    this.html.classList.add('fullscreen');
  }

  closeFullscreen() {
    this.section.classList.remove('fullscreen');
    this.media.classList.remove('fullscreen');
    this.html.classList.remove('fullscreen');
  }
}
