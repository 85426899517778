export default class Scrolly {
  constructor(element) {
    this.element = element;
    this.options = {
      rootMargin: '0px 0px 0px 0px',
    };

    this.init();
  }

  init() {
    const observer = new IntersectionObserver(
      this.watch.bind(this),
      this.options
    );

    const items = this.element.querySelectorAll('[data-scrolly]');
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      observer.observe(item);
    }
  }

  splitText(target) {
    const text = target.textContent;
    target.innerHTML = '';

    const words = text.split(' ');

    words.forEach((word, wordIndex) => {
      const wordContainer = document.createElement('div');
      wordContainer.classList.add('word');
      wordContainer.style.marginRight = '6px';

      if ('large' in target.dataset) {
        wordContainer.style.marginRight = '12px';
      }

      // Délai global pour chaque mot, pour l'apparition des mots en décalage
      wordContainer.style.animationDelay = `${wordIndex * 0.1}s`;

      word.split('').forEach((char, letterIndex) => {
        const letter = document.createElement('span');
        letter.textContent = char;
        letter.style.display = 'inline-block';

        // Ajustement du délai pour chaque lettre, avec un décalage progressif
        letter.style.animationDelay = `${
          wordIndex * 0.02 + letterIndex * 0.03
        }s`;

        wordContainer.appendChild(letter);
      });

      target.appendChild(wordContainer);
    });
  }

  watch(entries, observer) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      const target = entry.target;

      if (entry.isIntersecting) {
        target.classList.add('is-active');

        if ('splitText' in target.dataset) {
          this.splitText(target);
          target.classList.add('animated');
        }

        if ('noRepeat' in target.dataset) {
          observer.unobserve(target);
        }
      } else {
        target.classList.remove('is-active');
      }
    }
  }
}
