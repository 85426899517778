import lottieWeb from 'lottie-web';

export default class Lottie {
  constructor(element) {
    this.element = element;

    this.html = document.documentElement;
    this.container = this.element.querySelector('.container');
    this.path = this.container.dataset.path;

    this.init();
    this.setupScrollListener();
  }

  init() {
    const animation = lottieWeb.loadAnimation({
      container: this.container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `assets/lottie/${this.path}.json`,
    });
  }

  setOpacity() {
    const backgroundContainer = document.querySelector(
      '.background__animation'
    );

    // ScrollY et Hauteur de la fenêtre/document
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const speed = 10;

    // % du défilement multiplié pour accélérer le background subtil
    const scrollPercentage =
      (scrollTop / (documentHeight - windowHeight)) * speed;

    // Set l'opacité en 100% et 10% et applique sur le container
    const newOpacity = Math.max(1 - scrollPercentage, 0.1);
    backgroundContainer.style.opacity = newOpacity;
  }

  setupScrollListener() {
    window.addEventListener('scroll', this.setOpacity.bind(this));
  }
}
