import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class HorizontalScrolling {
  constructor(element) {
    this.element = element;

    this.init();
    this.setHorizontalScrolling();
  }

  init() {
    gsap.registerPlugin(ScrollTrigger);
  }

  setHorizontalScrolling() {
    let mm = gsap.matchMedia();

    mm.add('(min-width: 769px)', () => {
      const sections = gsap.utils.toArray('#horizontal .section.horizontal');

      const scrollAnimation = gsap.to(sections, {
        xPercent: -100 * (sections.length - 1),
        scrollTrigger: {
          trigger: '#horizontal',
          pin: true,
          scrub: 3,
          end: () => `+=${sections.length * window.innerWidth}`,
        },
      });

      return () => {
        scrollAnimation.kill();
        ScrollTrigger.getAll().forEach((st) => st.kill());
      };
    });
  }
}
