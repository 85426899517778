import LocomotiveScroll from 'locomotive-scroll';

export default class Locomotive {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    const locomotive = new LocomotiveScroll();
  }
}
