import MainMenu from './components/MainMenu';
import Scrolly from './components/Scrolly';
import Carousel from './components/Carousel';
import Accordion from './components/Accordion';
import Form from './components/Form';
import CustomCursor from './components/CustomCursor';
import Theme from './components/Theme';
import Lottie from './components/Lottie';
import Locomotive from './components/Locomotive';
import HorizontalScrolling from './components/HorizontalScrolling';
import PlayVideo from './components/PlayVideo';
import Atro from './components/Atro';
import Fullscreen from './components/Fullscreen';

export default class ComponentFactory {
  constructor() {
    this.componentList = {
      Form,
      Accordion,
      Scrolly,
      Carousel,
      MainMenu,
      CustomCursor,
      Theme,
      Lottie,
      Locomotive,
      HorizontalScrolling,
      PlayVideo,
      Atro,
      Fullscreen,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      if (this.componentList[componentName]) {
        new this.componentList[componentName](element);
      } else {
        console.log(`La composante ${componentName} n'existe pas!`);
      }
    }
  }
}
