export default class Form {
  constructor(element) {
    this.element = element;
    this.formElements = this.element.querySelector('.form').elements;

    this.onFocus();
    this.init();
  }

  init() {
    this.element.querySelector('.form').setAttribute('novalidate', '');

    for (let i = 0; i < this.formElements.length; i++) {
      const input = this.formElements[i];

      if (input.required) {
        input.addEventListener('input', this.validateInput.bind(this));
        input.addEventListener('blur', this.validateInput.bind(this));
      }
    }

    this.element.addEventListener('submit', this.onSubmit.bind(this));
  }

  onFocus() {
    for (let i = 0; i < this.formElements.length; i++) {
      const input = this.formElements[i];

      const inputContainer = input.closest('.input');

      input.addEventListener('focus', () => {
        inputContainer.classList.add('focus');
      });
      input.addEventListener('blur', () => {
        inputContainer.classList.remove('focus');
      });
    }
  }

  onSubmit(event) {
    event.preventDefault();

    if (this.validate()) {
      this.showConfirmation();
    } else {
      this.showError();
    }
  }

  validate() {
    let isValid = true;

    for (let i = 0; i < this.formElements.length; i++) {
      const input = this.formElements[i];

      if (input.required) {
        const inputValid = this.validateInput(input);

        if (!inputValid) {
          isValid = false;
        }
      }
    }

    return isValid;
  }

  validateInput(event) {
    const input = event.currentTarget || event;

    if (input.validity.valid) {
      this.removeError(input);
    } else {
      this.addError(input);
    }

    return input.validity.valid;
  }

  addError(input) {
    const container =
      input.closest('[data-input-container]') || input.closest('.input');
    container.classList.add('error');
  }

  removeError(input) {
    const container =
      input.closest('[data-input-container]') || input.closest('.input');
    container.classList.remove('error');
  }

  showConfirmation() {
    this.element.classList.add('is-sent');
  }

  showError() {
    this.element.classList.add('is-invalid');
  }
}
