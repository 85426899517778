export default class PlayVideo {
  constructor(element) {
    this.element = element;

    this.init();
    this.setVideoState();
  }

  init() {
    this.video = this.element.querySelector('video');
  }

  setVideoState() {
    this.video.addEventListener('mouseover', () => {
      this.video.play();
    });
    this.video.addEventListener('mouseleave', () => {
      this.video.pause();
    });
  }
}
