import Atropos from 'atropos';

export default class Atro {
  constructor(element) {
    this.element = element;

    this.instance = this.element.querySelector('.my-atropos');
    this.options = {
      el: this.instance,
      duration: 600,
      shadow: false,
      highlight: false,
      rotateXMax: 5,
      rotateYMax: 5,
      rotateTouch: 'scroll-y',
    };

    this.init();
  }

  init() {
    this.setOptions();

    const atropos = Atropos(this.options);
  }

  setOptions() {
    if ('large' in this.element.dataset) {
      (this.options.rotateXMax = 3), (this.options.rotateYMax = 3);
    }
  }
}
