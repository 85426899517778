export default class CustomCursor {
  constructor(element) {
    this.element = element;

    this.html = document.documentElement;
    this.cursor = this.element.querySelector('.cursor');
    this.mouseX = 0; // Position actuelle de la souris
    this.mouseY = 0; // Position actuelle de la souris
    this.cursorX = 0; // Position actuelle de l'image
    this.cursorY = 0; // Position actuelle de l'image
    this.delay = 0.05; // Facteur de retard (plus proche de 0 = plus lent)
    this.init();
  }

  init() {
    const links = document.querySelectorAll('a');
    const customButtons = document.querySelectorAll('.custom__btn');
    const hearts = document.querySelectorAll('.toggle__heart');

    // Mouvements de la souris
    document.addEventListener('mousemove', (e) => {
      if (this.html.classList.contains('heart')) {
        this.mouseX = e.clientX + 12;
        this.mouseY = e.clientY + 12;
      } else {
        this.mouseX = e.clientX + 3;
        this.mouseY = e.clientY + 2;
      }
    });

    // Ajout d'une boucle pour animer le curseur en retrait
    this.animateCursor();

    // Ajouter/retirer la classe 'large' pour les liens et les boutons custom
    links.forEach((link) => {
      link.addEventListener('mouseenter', () => {
        this.cursor.classList.add('large');
      });
      link.addEventListener('mouseleave', () => {
        this.cursor.classList.remove('large');
      });
    });

    customButtons.forEach((button) => {
      button.addEventListener('mouseenter', () => {
        this.cursor.classList.add('large');
      });
      button.addEventListener('mouseleave', () => {
        this.cursor.classList.remove('large');
      });
    });

    hearts.forEach((heart) => {
      heart.addEventListener('mouseenter', () => {
        this.html.classList.add('heart');
      });
      heart.addEventListener('mouseleave', () => {
        this.html.classList.remove('heart');
      });
    });
  }

  // Fonction pour interpoler la position et créer l'effet de "retard"
  animateCursor() {
    this.cursorX += (this.mouseX - this.cursorX) * this.delay; // Interpolation pour lisser le mouvement
    this.cursorY += (this.mouseY - this.cursorY) * this.delay;

    this.cursor.style.left = this.cursorX + 'px';
    this.cursor.style.top = this.cursorY + 'px';

    requestAnimationFrame(() => this.animateCursor()); // Boucle continue
    //console.log('Y: ' + this.cursorY + ' , X: ' + this.cursorX);
  }
}
