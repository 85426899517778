export default class Theme {
  constructor(element) {
    this.element = element;
    this.html = document.documentElement;
    this.body = document.body;

    this.init();
  }

  init() {
    const storedTheme = localStorage.getItem('theme');
    this.body.style.transition = 'none';

    setTimeout(() => {
      this.body.style.transition = 'background-color 1s ease';
    }, 1000);

    if (storedTheme) {
      this.html.classList.add(storedTheme);
    }

    const themeBtn = this.element.querySelector('.theme__toggle');
    themeBtn.addEventListener('click', this.changeTheme.bind(this));
  }

  changeTheme() {
    if (this.html.classList.contains('darkmode')) {
      this.html.classList.add('lightmode');
      this.html.classList.remove('darkmode');
      localStorage.setItem('theme', 'lightmode');
    } else {
      this.html.classList.add('darkmode');
      this.html.classList.remove('lightmode');
      localStorage.setItem('theme', 'darkmode');
    }
  }
}
